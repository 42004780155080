import { Flex, Text, Icon, Center, Box, Badge } from '_shared/designSystem/components';
import { Link, useMatches, useNavigate } from '@tanstack/react-location';
import PropTypes from 'prop-types';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { backButtonLinkToState } from '_shared/globalState/atoms';
import isEmpty from 'lodash/isEmpty';

export const MatchInfoHeader = ({ subheading, matchInfo, matchId, pointJoinKey }) => {
  let backButtonLinkTo = useRecoilValue(backButtonLinkToState);
  const matches = useMatches();

  const onReviewPage = matches.some((item) => {
    return item.id.includes('review');
  });

  const onMatchPage = matches.some((item) => {
    return item.id.includes('match');
  });

  if (onReviewPage) backButtonLinkTo = '/flagged';
  if (onMatchPage && !backButtonLinkTo.includes('review')) backButtonLinkTo = `/matches`;

  if (isEmpty(matchInfo)) return null;

  return (
    <Flex gap={2}>
      <Flex align="center">
        <Link to={backButtonLinkTo}>
          <Icon name="arrowLeft" color="primary.500" height={8} width={8} id="back" />
        </Link>
      </Flex>
      <Flex align="baseline" flexWrap="wrap">
        <Box mr={3} display={{ base: 'none', md: 'initial' }}>
          <PlayerNames subheading={subheading} matchInfo={matchInfo} />
        </Box>
        <Flex gap={2}>
          <Text fontSize="md" fontWeight="normal" color="grey.800">
            {matchInfo.competition_details}
          </Text>
          {subheading === 'Review' && <LinkToMatchPage matchId={matchId} pointJoinKey={pointJoinKey} />}
        </Flex>
        <Flex ml={2}>
         {matchInfo?.atp_draw_code && <Badge text={matchInfo?.atp_draw_code?.toString()} />}
        </Flex>
      </Flex>
    </Flex>
  );
};

MatchInfoHeader.propTypes = {
  subheading: PropTypes.string,
  matchInfo: PropTypes.object,
  matchId: PropTypes.number,
  pointJoinKey: PropTypes.any
};

const LinkToMatchPage = ({ matchId, pointJoinKey }) => {
  const navigate = useNavigate();
  const setbackButton = useSetRecoilState(backButtonLinkToState);

  const openMatchPage = () => {
    setbackButton(`/review/${pointJoinKey}?matchId=${matchId}`);
    navigate({ to: `/match/${matchId}` });
  };

  return (
    <Center cursor="pointer" title="Match Page" data-testid="matchLink" onClick={() => openMatchPage()}>
      <Icon name="tennisBall" color="primary.500" width="13px" height="13px" />
    </Center>
  );
};

const PlayerNames = ({ matchInfo, subheading }) => {
  if (!subheading && !matchInfo.player1Name) {
    return (
      <Text fontSize="3xl" fontWeight="medium" color="grey.800">
        {matchInfo.players}
      </Text>
    );
  }

  const openPlayerInformation = (player) => {
    window.open(matchInfo[`${player}ExternalDetailsUrl`], '_blank');
  };

  const showExternalLink = ['Collect', 'Review'].includes(subheading);

  return (
    <Flex gap={1}>
      {subheading && (
        <Text fontSize="3xl" fontWeight="medium" color="grey.800">
          {subheading} -
        </Text>
      )}
      {showExternalLink && (
        <ExternalLinkIcon matchInfo={matchInfo} openPlayerInformation={openPlayerInformation} player="player1" />
      )}
      <Text fontSize="3xl" fontWeight="medium" color="grey.800">
        {`${matchInfo.player1Name}`} v {`${matchInfo.player2Name}`}
      </Text>
      {showExternalLink && (
        <ExternalLinkIcon matchInfo={matchInfo} openPlayerInformation={openPlayerInformation} player="player2" />
      )}
    </Flex>
  );
};

const ExternalLinkIcon = ({ matchInfo, player, openPlayerInformation }) => {
  if (!matchInfo[`${player}ExternalDetailsUrl`]) return null;
  return (
    <Center
      data-testid={`externalLink${player}`}
      cursor="pointer"
      title={`${matchInfo[`${player}Name`]} Player Profile`}
      onClick={() => openPlayerInformation(player)}
    >
      <Icon name="externalLink" color="primary.500" width="16px" height="16px" />
    </Center>
  );
};
