import { ErrorMessage, Flex, Text } from '_shared/designSystem/components';
import isEmpty from 'lodash/isEmpty';
import { capitaliseString } from '_shared/utils/stringUtil';

export default function ComparisonScores(props) {
  const { data, player1Name, player2Name } = props;

  if (isEmpty(data)) {
    return <ErrorMessage message="No comparison scores" />;
  }

  const updateSituationWithPlayerName = (situation) => {
    if (situation === 'player1_at_net') return `${player1Name} At Net`;
    else if (situation === 'player2_at_net') return `${player2Name} At Net`;
    return situation;
  };

  return (
    <Flex direction="column" minW="310px" maxW="470px" gap={3}>
      <Flex justify="space-between">
        <Text fontWeight="bold">Manual</Text>
        <Text fontWeight="bold">Automated</Text>
      </Flex>
      <Row leftValue={data?.manual?.rally_length} heading="Shots" rightValue={data?.auto?.rally_length} />
      <Row leftValue={data?.manual?.last_shot_type} heading="Last Shot" rightValue={data?.auto?.last_shot_type} />
      <Row leftValue={data?.manual?.point_outcome} heading="Outcome" rightValue={data?.auto?.point_outcome} />
      <Row leftValue={data?.manual?.out_type} heading="Outcome Type" rightValue={data?.auto?.out_type} />
      <Row
        leftValue={updateSituationWithPlayerName(data?.manual?.situation)}
        heading="Situation"
        rightValue={updateSituationWithPlayerName(data?.auto?.situation)}
      />
    </Flex>
  );
}

const Row = ({ leftValue, heading, rightValue }) => {
  const conflict = leftValue !== rightValue;
  return (
    <Flex justify="space-between">
      <Text fontSize="sm" w="110px" textAlign="left">
        {leftValue ? capitaliseString(leftValue) : ''}
      </Text>
      <Text fontSize="sm" fontWeight="semibold" w="100px" textAlign="center">
        {heading}
      </Text>
      <Text
        fontWeight={conflict ? 'semibold' : null}
        color={conflict ? '#FF0000' : null}
        fontSize="sm"
        w="110px"
        textAlign="right"
      >
        {rightValue ? capitaliseString(rightValue) : ''}
      </Text>
    </Flex>
  );
};
