import { Box } from '_shared/designSystem/components';

export const SearchWrapper = ({ children, topHeaderHeight }) => (
  <Box
    position={{ base: 'relative', md: 'sticky' }}
    top={{ base: null, md: topHeaderHeight }}
    zIndex={{ base: 2, md: null }}
    bg="white"
    px={{ base: 2, md: null }}
    pt={{ base: 2, md: null }}
  >
    {children}
  </Box>
);
