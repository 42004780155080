import { getLocalStorageAuthTokens } from '_shared/utils/localStorage';
import { Navigate } from '@tanstack/react-location';

const Authentication = ({ children }) => {
  const authenticated = process.env.REACT_APP_ENV === 'dev' ? true : checkUserLoggedIn();

  if (!authenticated) {
    return <Navigate to="./login" />;
  }

  return children;
};

function checkUserLoggedIn() {
  const userData = getLocalStorageAuthTokens();
  return userData?.loggedIn === 'yes';
}

export default Authentication;
