import { useEffect } from 'react';
import { Box, Flex, ErrorMessage, Spinner } from '@ellipsedata/tennis';
import { stickyHeaderHeight, topMenuHeight } from '_layouts/margins';
import { useSetRecoilState } from 'recoil';
import { mobileHeaderTextState, backButtonLinkToState } from '_shared/globalState/atoms';
import MatchesTable from './MatchesTable';
import { useNavigate } from '@tanstack/react-location';
import { useMatchesQuery } from 'matches/hooks/matchesHooks';
import StickyHeader from '_shared/components/StickyHeader';

export default function Matches() {
  const setMobileHeaderText = useSetRecoilState(mobileHeaderTextState);
  const setbackButton = useSetRecoilState(backButtonLinkToState);

  const { isLoading, error, data, isSuccess } = useMatchesQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      handleScroll();
    }
  }, [isSuccess]);

  useEffect(() => {
    setMobileHeaderText('Matches');
  }, [setMobileHeaderText]);

  const openMatchPage = (matchId, navigate) => {
    setbackButton('/matches');
    sessionStorage.setItem('matchesScrollPosition', window.pageYOffset);
    navigate({ to: `/match/${matchId}` });
  };

  if (error)
    return (
      <Box>
        <StickyHeader text="Matches" />
        <ErrorMessage message="Unable to retrieve matches, please try refreshing the page." />
      </Box>
    );

  if (isLoading)
    return (
      <Box>
        <StickyHeader text="Matches" />
        <Flex pt={100} justify="center">
          <Spinner color="primary.500" />
        </Flex>
      </Box>
    );

  const handleScroll = () => {
    const matchesScrollPosition = sessionStorage.getItem('matchesScrollPosition');
    if (matchesScrollPosition) {
      window.scrollTo(0, parseInt(matchesScrollPosition));
      sessionStorage.removeItem('matchesScrollPosition');
    }
  };

  return (
    <Box>
      <StickyHeader text="Matches" />
      <MatchesTable
        data={data}
        headerTop={{ base: 0, md: `${topMenuHeight + stickyHeaderHeight}px` }}
        openMatchPage={openMatchPage}
        navigate={navigate}
      />
    </Box>
  );
}
