import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

export function capitaliseString(string) {
  if (!string) return null;
  return startCase(toLower(string));
}

export function capitaliseFirstLetterOfString(string) {
  if (!string) return null;
  return string.charAt(0).toUpperCase() + toLower(startCase(toLower(string)).slice(1));
}

export function generateRandomId() {
  return Math.random().toString(36).substring(2, 9);
}

export function generateRandomNumber() {
  return Math.floor(100000 + Math.random() * 900000);
}
