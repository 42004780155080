import { parseISO, format, compareAsc } from 'date-fns';

export function formatDate(date) {
  if (!date) return '';

  try {
    const formattedDate = format(parseISO(date.replace(' ', 'T') + 'Z'), 'd MMM yyyy');
    return formattedDate;
  } catch (error) {
    console.error('Invalid date format:', error);
    return '';
  }
}

export function formatTime(date) {
  try {
    if (!date) return '';
    const parsedDate = parseISO(date.replace(' ', 'T') + 'Z');
    return format(parsedDate, 'HH:mm');
  } catch (error) {
    console.error('Error formatting time:', error);
    return '';
  }
}

export function moreThanOneMinAgo(dateTime) {
  const timeOneMinAgo = new Date(Date.now() - 1000 * 60);
  const formattedDateTime = parseISO(dateTime?.replace(' ', 'T') + 'Z');
  const isMoreThan1MinInPast = compareAsc(timeOneMinAgo, formattedDateTime);
  return isMoreThan1MinInPast === 1;
}
