import { extendTheme } from '@chakra-ui/react';

import colors from './colors';
import { fonts, fontSizes } from './typography';
import { ButtonStyles } from '_shared/designSystem/components/button/Button';
import { breakpoints } from './breakpoints';

// we override the default Chakra theme with our custom themes here
const overrides = {
  colors,
  fonts,
  fontSizes,
  components: {
    Button: ButtonStyles,
    Divider: {
      baseStyle: {
        bg: 'gray.500'
      },
      variants: {
        menuSelected: {
          bg: 'primary.700',
          height: '2px'
        }
      }
    }
  },
  breakpoints
};

const theme = extendTheme(overrides);

// We also inherit all of Chakra's default theme values
// https://chakra-ui.com/docs/theming/theme

export default theme;
