import { Box, Flex, Text, Center, Icon } from '_shared/designSystem/components';
import PlayerDetails from './PlayerDetails';
import { resultName, matchStatus } from '_shared/constants/matchTypes';
import MatchStatusText from './MatchStatusText';

export const MatchScore = (props) => {
  const { data, player1Name, player2Name } = props;
  const players = ['player1', 'player2'];
  return (
    <Box>
      <table variant="unstyled" size="sm">
        <tbody>
          {players.map((item) => (
            <tr height="32px" key={item}>
              <td>
                <Box mr={4}>
                  <PlayerDetails name={item === 'player1' ? player1Name : player2Name} />
                </Box>
              </td>
              <td>
                <ServerIcon data={data} player={item} />
              </td>
              <td>
                <Box mr={3}>
                  <GameScore setScores={data?.match_score?.set_scores} player={item} />
                </Box>
              </td>
              {data?.match_status === matchStatus.LIVE &&
                data?.match_score?.in_progress_game_score?.[`${item}_points`] !== '' && (
                  <td>
                    <PointScore score={data?.match_score?.in_progress_game_score?.[`${item}_points`]} />
                  </td>
                )}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

function ServerIcon(props) {
  const { data, player } = props;

  switch (data?.match_status) {
    case matchStatus.SUSPENDED:
      return <MatchStatusText result={matchStatus.SUSPENDED} type="matchScore" />;
    case matchStatus.LIVE:
      return data?.match_score?.in_progress_game_score?.player_serving === player ? <ServingIcon /> : null;
    case matchStatus.COMPLETE:
      const hasWon = data?.match_result?.match_winner === player;
      switch (data?.match_result?.result_name) {
        case resultName.WON:
          return hasWon ? <TickIcon /> : null;
        case resultName.WALKOVER:
        case resultName.RETIRED:
        case resultName.DEFAULTED:
          return hasWon ? <TickIcon /> : <MatchStatusText result={data?.match_result?.result_name} type="matchScore" />;
        case resultName.ABANDONED:
          return <MatchStatusText result={data?.match_result?.result_name} type="matchScore" />;
        case resultName.CANCELLED:
          return <MatchStatusText result={data?.match_result?.result_name} type="matchScore" />;
        default:
          return null;
      }
    case matchStatus.FORTHCOMING:
      return null;
    default:
      return null;
  }
}

function GameScore(props) {
  const { setScores, player } = props;
  return (
    <Flex gap={5}>
      {setScores?.map((item, index) => (
        <Center key={index}>
          <Text fontSize="sm" fontWeight="medium" color="grey.800">
            {item[`${player}_games`]}
          </Text>
        </Center>
      ))}
    </Flex>
  );
}

function PointScore(props) {
  const { score } = props;
  return (
    <Center bg="primary.500" h={6} w={6} borderRadius={'5px'}>
      <Text fontSize="sm" fontWeight="medium" color="white">
        {score}
      </Text>
    </Center>
  );
}

const TickIcon = () => (
  <Center boxSize="32px">
    <Icon name="tick" color="primary.500" height={6} width={6} />
  </Center>
);

const ServingIcon = () => (
  <Center boxSize="32px">
    <Icon name="tennisBall" color="primary.500" height={4} width={4} />
  </Center>
);

MatchStatusText.defaultProps = {
  type: 'matchList'
};
