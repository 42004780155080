import { configureAbly } from '@ably-labs/react-hooks';
import { generateRandomId } from '_shared/utils/stringUtil';
import { authenticationService } from 'authentication/services/authenticationService';
import { useQuery } from 'react-query';
import { Box } from '_shared/designSystem/components';
import { ablyState } from '_shared/globalState/atoms';
import { useSetRecoilState } from 'recoil';

export default function AblyConnection() {
  const setAblyState = useSetRecoilState(ablyState);

  useQuery(['authenticationService_getAblyToken'], () => authenticationService.getAblyToken(), {
    onSuccess: (data) => {
      configureAbly({ key: data?.access_token, clientId: generateRandomId() });
      setAblyState(true);
    },
    staleTime: 1800000 // get new access token after 30 min
  });
  return <Box />;
}
