import { apiClient } from '_shared/dataFetching/apiConfig';

export const matchesService = {
  async getMatches() {
    const url = `matches`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  }
};
