import isEmpty from 'lodash/isEmpty';

export function performAllFlaggedSearch(data, matchId, tournamentId, dataStatus) {
  const filteredByMatchId = filterByMatchId(data, matchId);
  const filteredByTournamentId = filterByTourmamentId(filteredByMatchId, tournamentId);
  const filteredByDataStatus = filterByDataStatus(filteredByTournamentId, dataStatus);
  return filteredByDataStatus;
}

export function filterByMatchId(data, matchId) {
  if (isEmpty(data)) return [];
  if (!matchId) return data;
  let result = [];
  result = data.filter((item) => item?.match_id === parseInt(matchId));
  return result;
}

export function filterByTourmamentId(data, tournamentId) {
  if (isEmpty(data)) return [];
  if (!tournamentId) return data;
  let result = [];
  result = data.filter((item) => item?.comp_id === parseInt(tournamentId));
  return result;
}

export function filterByDataStatus(data, dataStatus) {
  if (isEmpty(data)) return [];
  if (!dataStatus || dataStatus === 'all') return data;
  let result = [];
  result = data.filter((item) => item?.data_status === dataStatus);
  return result;
}
