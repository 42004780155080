import { Icon, Toast } from '_shared/designSystem/components';
import { Text, Box, Flex, Table, Tbody, Td, Th, Thead, Tr, Alert, Badge } from '@ellipsedata/tennis';
import { matchStatus } from '_shared/constants/matchTypes';
import { resultName } from '_shared/constants/matchTypes';
import MatchStatusText from 'matches/components/MatchStatusText';
import { mainAreaMaxWidth } from '_layouts/margins';
import { formatDate, formatTime } from '_shared/utils/dateUtil';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { capitaliseString } from '_shared/utils/stringUtil';
import { userPermissionTypes } from '_shared/constants/user';
import { userPermissionsState } from '_shared/globalState/atoms';
import { checkPermission } from '_shared/utils/permissions';
import { useRecoilValue } from 'recoil';

export default function MatchesTable(props) {
  const { data, headerTop, openMatchPage, navigate } = props;

  const { permissions } = useRecoilValue(userPermissionsState);

  const { addToast } = Toast();

  if (isEmpty(data))
    return (
      <Box mt={5}>
        <Alert message="No matches to show" status="info" />
      </Box>
    );

  return (
    <Box maxW={`${mainAreaMaxWidth}px`}>
      <Box overflowX={{ base: 'auto', md: 'initial' }}>
        <Table size="md" fontSize="sm" fontWeight="normal" color="grey.500">
          <Thead position="sticky" top={headerTop} zIndex={2} bg="white">
            <Tr>
              <HeaderCell>Players</HeaderCell>
              <HeaderCell>Score</HeaderCell>
              <HeaderCell>Tournament</HeaderCell>
              <HeaderCell>Draw Code</HeaderCell>
              <HeaderCell>Round</HeaderCell>
              <HeaderCell>Date</HeaderCell>
              <HeaderCell>Start Time</HeaderCell>
              <HeaderCell>Court</HeaderCell>
              <HeaderCell>Collection Status</HeaderCell>
              <HeaderCell>Collected By</HeaderCell>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((item) => (
              <Tr
                _hover={{ bg: 'primary.25' }}
                cursor="pointer"
                onClick={() => {
                  if (checkPermission(userPermissionTypes.COLLECTION, permissions))
                    openMatchPage(item.match_id, navigate);
                  else addToast({ message: 'You do not have permission to view this content', status: 'warning' });
                }}
                key={item.match_id}
              >
                <Td whiteSpace="nowrap">
                  <PlayerNames item={item} />
                </Td>
                <Td whiteSpace="nowrap">
                  <MatchScore item={item} />
                </Td>
                <Td>{item.competition}</Td>
                <Td>{item?.atp_draw_code}</Td>
                <Td>{item.round}</Td>
                <Td whiteSpace="nowrap">{formatDate(item.start_time)}</Td>
                <Td whiteSpace="nowrap">{formatTime(item.start_time)}</Td>
                <Td>{item.court_id}</Td>
                <Td>{capitaliseString(item.data_status)}</Td>
                <Td>{item.collected_by}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}

MatchesTable.propTypes = {
  data: PropTypes.array,
  headerTop: PropTypes.object,
  openMatchPage: PropTypes.func,
  navigate: PropTypes.func
};

const HeaderCell = ({ children }) => <Th height={8}>{children}</Th>;

const TennisBallIcon = () => (
  <Flex height="20px" align="center">
    <Icon name="tennisBall" color="primary.500" height={2.5} width={2.5} />
  </Flex>
);

const TickIcon = () => (
  <Flex height="20px" align="center">
    <Icon name="tick" color="primary.500" height={4} width={4} />
  </Flex>
);

export const PlayerNames = ({ item }) => {
  return (
    <Flex>
      <PlayerStatus item={item} player="player1" position="left" />
      <Text>{item.players}</Text>
      <PlayerStatus item={item} player="player2" position="right" />
    </Flex>
  );
};

const PlayerStatusWrapper = ({ position, children }) => {
  if (!children) return null;
  return (
    <Box mr={position === 'left' ? 1 : 0} ml={position === 'right' ? 1 : 0}>
      {children}
    </Box>
  );
};

export const PlayerStatus = ({ item, player, position }) => {
  let displayResult;
  switch (item?.match_status) {
    case matchStatus.FORTHCOMING:
      return null;
    case matchStatus.LIVE:
    case matchStatus.SUSPENDED:
      if (item?.score?.player_serving === player) displayResult = <TennisBallIcon />;
      break;
    case matchStatus.COMPLETE:
      switch (item?.score?.result_name) {
        case resultName.ABANDONED:
        case resultName.CANCELLED:
          break;
        case resultName.RETIRED:
        case resultName.DEFAULTED:
        case resultName.WALKOVER:
        case resultName.WON:
          if (item?.score?.match_winner === player) displayResult = <TickIcon />;
          break;
        default:
      }
      break;
    default:
  }
  return <PlayerStatusWrapper position={position}>{displayResult}</PlayerStatusWrapper>;
};

export const MatchScore = ({ item }) => {
  switch (item.match_status) {
    case matchStatus.FORTHCOMING:
      return <Text color="grey.500">Upcoming</Text>;
    case matchStatus.LIVE:
      return (
        <Flex gap={3}>
          <Badge text="Live" colour="rose" withleftCircle />
          <Text>{item?.score?.match_score}</Text>
          <Text color="primary.500" fontWeight="medium">
            {item?.score?.point_score}
          </Text>
        </Flex>
      );
    case matchStatus.SUSPENDED:
      return (
        <Flex gap={3}>
          <MatchStatusText result={item.match_status} color="grey.500" />
          <Text>{item?.score?.match_score}</Text>
          <Text color="primary.500" fontWeight="medium">
            {item?.score?.point_score}
          </Text>
        </Flex>
      );
    case matchStatus.COMPLETE:
      if (
        item?.score?.result_name === resultName.WALKOVER ||
        item?.score?.result_name === resultName.RETIRED ||
        item?.score?.result_name === resultName.DEFAULTED
      )
        return <Text>{item?.score?.match_score}</Text>;
      else if (item?.score?.result_name === resultName.ABANDONED) {
        return <MatchStatusText result={item?.score?.result_name} color="grey.500" />;
      } else {
        return (
          <Flex gap={3}>
            <MatchStatusText result={item?.score?.result_name} color="grey.500" />
            <Text>{item?.score?.match_score}</Text>
          </Flex>
        );
      }
    default:
      return null;
  }
};
