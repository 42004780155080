import { collectFieldType } from '_shared/constants/collectionTypes';
import { apiClient } from '_shared/dataFetching/apiConfig';

export const reviewService = {
  async getReview({ matchId, pointJoinKey }) {
    const url = `review/${matchId}/point/${pointJoinKey}`;
    const res = await apiClient.get(url);
    return res.data;

  },
  async postReview({ matchId, pointJoinKey, set, game, point, formData }) {
    const bodyFormData = new FormData();

    bodyFormData.append('set', set);
    bodyFormData.append('game', game);
    bodyFormData.append('point', point);

    Object.keys(formData).forEach((item) => {
      if (item === collectFieldType.RALLY_LENGTH && formData[`${item}`] === null) {
        bodyFormData.append(item, ' '); // this forces an error return, so user has to fix before submitting again
      } else if (formData[`${item}`] === null) {
        bodyFormData.append(item, '');
      } else {
        bodyFormData.append(item, formData[`${item}`]);
      }
    });
    const headers = {
      'Content-Type': 'application/form-data'
    };
    const url = `review/${matchId}/point/${pointJoinKey}`;
    const res = await apiClient.post(url, bodyFormData, { headers: headers });
    return res.data;
  },

  async postCannotReview({ matchId, pointJoinKey, set, game, point, fieldName, value }) {
    const formData = new FormData();
    formData.append('set', set);
    formData.append('game', game);
    formData.append('point', point);
    formData.append(fieldName, value);
    const headers = {
      'Content-Type': 'application/form-data',
      Username: localStorage?.userName
    };
    const url = `review/${matchId}/point/${pointJoinKey}/cannot_review`;
    const res = await apiClient.post(url, formData, { headers: headers });
    return res.data;
  }
};
