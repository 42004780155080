import { Text, Box, VStack, Button, Flex } from '_shared/designSystem/components';
import isEmpty from 'lodash/isEmpty';

export default function NextPointModalBody(props) {
  const { onClose, handleContinue, flags, text, isLoading } = props;
  return (
    <Box p={6}>
      <VStack gap={10}>
        <Text fontSize="md" fontWeight="normal" color="grey.700">
          {text || 'Are you sure you want to move onto the next point? Issues:'}
        </Text>
        {!isEmpty(flags) && (
          <Flex direction="column">
            {flags.map((item, index) => (
              <Text key={index} color="error.400">
                {item}
              </Text>
            ))}
          </Flex>
        )}
        <Flex gap={10}>
          <Button size="sm" onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button size="sm" onClick={() => handleContinue()} isLoading={isLoading}>
            Yes I am sure
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
}
