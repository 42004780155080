import { Box, Flex, Text } from '_shared/designSystem/components';
import PropTypes from 'prop-types';

export default function Badge(props) {
  const { text, colour, withleftCircle, rounded } = props;
  return (
    <Flex bg={`${colour}.50`} px={2} display="inline-block" borderRadius={rounded ? '16px' : null}>
      <Flex gap={2}>
        {withleftCircle && (
          <Flex alignItems="center" justifyContent="center">
            <Box w="6px" h="6px" borderRadius={'6px'} bg={`${colour}.500`} />
          </Flex>
        )}
        <Text fontSize="xs" fontWeight="medium" color={`${colour}.700`}>
          {text}
        </Text>
      </Flex>
    </Flex>
  );
}

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  colour: PropTypes.string,
  rounded: PropTypes.bool
};

Badge.defaultProps = {
  colour: 'primary',
  rounded: true,
  withleftCircle: false
};
