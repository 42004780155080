import { apiClient } from '_shared/dataFetching/apiConfig';

export const performanceService = {
  async getPerformance() {
    const url = `performance`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  }
};
