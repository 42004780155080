export const resultName = {
  WON: 'won',
  LOST: 'lost',
  WALKOVER: 'walkover',
  RETIRED: 'retired',
  DEFAULTED: 'defaulted',
  ABANDONED: 'abandoned',
  CANCELLED: 'cancelled'
};

export const matchStatus = {
  FORTHCOMING: 'forthcoming',
  LIVE: 'live',
  COMPLETE: 'complete',
  SUSPENDED: 'suspended'
};
