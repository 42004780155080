import { Text, Flex, Center, Icon } from '_shared/designSystem/components';

export default function Logo(props) {
  const { iconOnly } = props;
  return (
    <Center bg="grey.800" h="50px">
      <Flex gap={2}>
        <Icon name="logoWhite" width="24px" height="24px" mt="2px" />
        {!iconOnly && (
          <Flex gap={1}>
            <Text as="i" color="primary.500" fontSize="lg" fontWeight="bold">
              L2
            </Text>
            <Text color="white" fontSize="lg" fontWeight="medium">
              COLLECTION
            </Text>
          </Flex>
        )}
      </Flex>
    </Center>
  );
}
