import { Modal, ModalOverlay, ModalContent } from '_shared/designSystem/components';
import PropTypes from 'prop-types';
import NextPointModalBody from './NextPointModalBody';

export default function NextPointModal(props) {
  const { handleContinue, isOpen, onClose, text, flags, showNextPointModalLoading } = props;

  return (
    <Modal isOpen={isOpen} size="lg" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <NextPointModalBody
          onClose={onClose}
          handleContinue={handleContinue}
          flags={flags}
          text={text}
          isLoading={showNextPointModalLoading}
        />
      </ModalContent>
    </Modal>
  );
}

NextPointModal.propTypes = {
  handleContinue: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  flags: PropTypes.array
};
