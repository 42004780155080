import { Flex, Text, Icon, Center } from '_shared/designSystem/components';
import { authenticationService } from 'authentication/services/authenticationService';
import { useQuery } from 'react-query';
import { useNavigate } from '@tanstack/react-location';

export default function Logout(props) {
  const { iconOnly } = props;
  const navigate = useNavigate();

  const { refetch } = useQuery('authenticationService_logout', () => authenticationService.logout(), {
    enabled: false
  });

  const handleLogout = () => {
    refetch();
    navigate({ to: '/login' });
  };

  return (
    <Flex gap={3} cursor="pointer" onClick={() => handleLogout()}>
      {!iconOnly && (
        <Text fontWeight="medium" color="grey.600">
          Log Out
        </Text>
      )}
      <Center>
        <Icon width="20px" height="20px" name="logOut" color="grey.600" />
      </Center>
    </Flex>
  );
}
