import { Box, Divider, Flex, Text } from '_shared/designSystem/components';
import Logo from '_shared/components/logo/Logo';
import { Link } from '@tanstack/react-location';
import Logout from 'authentication/components/Logout';
import { sideMenuWidth } from '_layouts/margins';
import { userPermissionTypes } from '_shared/constants/user';
import { userPermissionsState } from '_shared/globalState/atoms';
import { checkPermission } from '_shared/utils/permissions';
import { useRecoilValue } from 'recoil';

export default function SideMenu({ isMobile, closeMenu }) {
  const { permissions } = useRecoilValue(userPermissionsState);

  return (
    <Box
      overflow="auto"
      minW={`${sideMenuWidth}px`}
      maxW={`${sideMenuWidth}px`}
      borderRight="1px"
      borderRightColor="grey.200"
    >
      <Flex direction="column" h="100vh">
        <Link to="/" key="home">
          <Box pr={isMobile ? '75px' : 0}>
            <Logo />
          </Box>
        </Link>
        <Flex h="100%" direction="column" justify="space-between" p={6} bg="grey.25">
          <Flex direction="column" gap={5}>
            <MenuItemRow label="Matches" link="/matches" closeMenu={closeMenu} />
            {checkPermission(userPermissionTypes.FLAGGED, permissions) && (
              <MenuItemRow label="Flagged" link="/flagged" closeMenu={closeMenu} />
            )}
            {checkPermission(userPermissionTypes.COLLECTION, permissions) && (
              <MenuItemRow label="Performance" link="/performance" closeMenu={closeMenu} />
            )}
          </Flex>
          <Box h="60px">
            <Divider />
            <Logout />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

const MenuItemRow = ({ label, link, closeMenu }) => (
  <Link
    to={link}
    key={link}
    activeOptions={{
      exact: link === '.'
    }}
  >
    {({ isActive }) => {
      return (
        <Flex gap={3} onClick={() => closeMenu()}>
          <Flex alignContent="center">
            <Text fontWeight="medium" color={isActive ? 'primary.700' : 'grey.600'}>
              {label}
            </Text>
          </Flex>
        </Flex>
      );
    }}
  </Link>
);
