import { mainAreaMarginLeft, mainAreaMaxWidth, topMenuHeight } from '_layouts/margins';
import { Box, Text } from '_shared/designSystem/components';

const StickyHeader = ({ text }) => (
  <Box
    px={`${mainAreaMarginLeft}px`}
    maxW={`${mainAreaMaxWidth}px`}
    position="sticky"
    top={`${topMenuHeight}px`}
    zIndex={2}
    bg="white"
    h={{ base: 0, md: '50px' }}
  >
    <Text display={{ base: 'none', md: 'initial' }} fontSize="3xl" fontWeight="medium" color="grey.800">
      {text}
    </Text>
  </Box>
);

export default StickyHeader;
