import matchesRoutes from 'matches/routes';
import matchRoutes from 'match/routes';
import flaggedRoutes from 'flagged/routes';
import reviewRoutes from 'review/routes';
import collectRoutes from 'collect/routes';
import performanceRoutes from 'performance/routes';
import { Navigate } from '@tanstack/react-location';
import Page404 from '_shared/errors/Page404';

export const authenticatedRoutes = [
  { path: '/', element: <Navigate to="/matches" /> },
  matchesRoutes,
  matchRoutes,
  flaggedRoutes,
  reviewRoutes,
  collectRoutes,
  performanceRoutes,
  { path: '*', element: <Page404 /> }
];
