import Match from 'match/components/Match';

const routes = {
  path: 'match',
  children: [
    {
      path: ':matchId',
      element: <Match />
    }
  ]
};

export default routes;
