// margins used across the site

export const topMenuHeight = 51;
export const stickyHeaderHeight = 50;
export const mainAreaTop = 16;
export const mainAreaPaddingX = 20;
export const mainAreaWidth = 1137;
export const sideMenuWidth = 220;
export const sideMenuNarrowWidth = 54;
export const matchHeaderHeight = 121;
export const mainAreaMarginLeft = 20;
export const mainAreaMaxWidth = 1800;
export const mobileHeaderHeight = 40;
export const mobileMatchHeaderHeight = 290;
