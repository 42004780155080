import { Text, Flex } from '_shared/designSystem/components';
import { resultName, matchStatus } from '_shared/constants/matchTypes';

const MatchStatusText = ({ result, type }) => {
  let returnResult;
  switch (result) {
    case resultName.WALKOVER:
      returnResult = 'w/o';
      break;
    case resultName.RETIRED:
      returnResult = 'ret.';
      break;
    case resultName.DEFAULTED:
      returnResult = 'def.';
      break;
    case resultName.ABANDONED:
      returnResult = 'aba.';
      break;
    case resultName.CANCELLED:
      returnResult = 'can.';
      break;
    case matchStatus.SUSPENDED:
      returnResult = 'sus.';
      break;
    default:
      return null;
  }
  return type === 'matchList' ? (
    <Text color="grey.500">{returnResult}</Text>
  ) : (
    <Flex h="20px" alignItems="flex-end">
      <Text fontSize="xs" fontWeight="normal" color="grey.800" mr={2}>
        {returnResult}
      </Text>
    </Flex>
  );
};

export default MatchStatusText;
