import { Modal, ModalOverlay, ModalContent, Flex, Text, ModalCloseButton } from '_shared/designSystem/components';
import { capitaliseString } from '_shared/utils/stringUtil';

export const PointModal = ({ data, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <PointModalBody data={data} />
      </ModalContent>
    </Modal>
  );
};

export const PointModalBody = ({ data }) => (
  <Flex direction="column" gap={5} p={10}>
    <PointTableRow label="Rally Length" value={data?.rally_length} />
    <PointTableRow label="Outcome" value={data?.point_outcome} />
    <PointTableRow label="Last Shot Type" value={data?.last_shot_type} />
    {data?.out_type && <PointTableRow label="Out Type" value={data?.out_type} />}
    <PointTableRow label="Situation" value={data?.situation} />
  </Flex>
);

const PointTableRow = ({ label, value }) => (
  <Flex>
    <Text w="200px" fontWeight="bold">
      {label}
    </Text>
    <Text w="200px">{capitaliseString(value)}</Text>
  </Flex>
);
