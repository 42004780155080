import { Box, Table, Tbody, Td, Th, Thead, Tr, Alert } from '_shared/designSystem/components';
import { mainAreaMaxWidth } from '_layouts/margins';
import { formatDate, formatTime } from '_shared/utils/dateUtil';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { capitaliseString } from '_shared/utils/stringUtil';
import { checkPermission } from '_shared/utils/permissions';
import { userPermissionsState } from '_shared/globalState/atoms';
import { userPermissionTypes } from '_shared/constants/user';
import { useRecoilValue } from 'recoil';
export default function PointHistoryTable(props) {
  const { data, openPointData } = props;

  const { permissions } = useRecoilValue(userPermissionsState);

  if (isEmpty(data))
    return (
      <Box mt={5} px={4}>
        <Alert message="No collected points to show" status="info" />
      </Box>
    );

  return (
    <Box maxW={`${mainAreaMaxWidth}px`}>
      <Box overflowX={{ base: 'auto', md: 'initial' }}>
        <Table size="md" fontSize="sm" fontWeight="normal" color="grey.500">
          <Thead>
            <Tr>
              <HeaderCell>Score</HeaderCell>
              <HeaderCell>Set</HeaderCell>
              <HeaderCell>Game</HeaderCell>
              <HeaderCell>Point</HeaderCell>
              {checkPermission(userPermissionTypes.POINT_STATUS, permissions) && <HeaderCell>Status</HeaderCell>}
              <HeaderCell>Collector</HeaderCell>
              <HeaderCell>Collected At</HeaderCell>
              <HeaderCell>Reviewed At</HeaderCell>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((item) => (
              <Tr
                _hover={{ bg: 'primary.25' }}
                cursor="pointer"
                onClick={() => openPointData(item)}
                key={item.point_join_key}
              >
                <Td whiteSpace="nowrap">{`${item?.game_score} ${item?.point_score}`}</Td>
                <Td>{item?.set}</Td>
                <Td>{item?.game}</Td>
                <Td>{item?.point}</Td>
                {checkPermission(userPermissionTypes.POINT_STATUS, permissions) && (
                  <Td>{capitaliseString(item?.data_status)}</Td>
                )}
                <Td>{item?.collection_user}</Td>
                <Td whiteSpace="nowrap">{`${formatDate(item.collection_time)} ${formatTime(item.collection_time)}`}</Td>
                <Td whiteSpace="nowrap">{`${formatDate(item.reviewed_time)} ${formatTime(item.reviewed_time)}`}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}

PointHistoryTable.propTypes = {
  data: PropTypes.array,
  openPointData: PropTypes.func
};

const HeaderCell = ({ children }) => <Th height={8}>{children}</Th>;
