import { useChannel } from '@ably-labs/react-hooks';
import { Box } from '_shared/designSystem/components';

export const AblyUpdaterHeader = ({ ablyChannelName, setLiveScore }) => {
  useChannel(ablyChannelName, 'header', (message) => {
    const data = message?.data;
    const newLiveScore = {
      match_status: data?.match_status,
      match_score: {
        set_scores: data?.match_score?.set_scores,
        in_progress_game_score: data?.match_score?.in_progress_game_score
      },
      match_result: data?.match_result
    };
    setLiveScore(newLiveScore);
  });
  return <Box />;
};
