export function getLastShotButtons() {
  return [
    {
      label: 'Serve',
      value: 'serve'
    },
    {
      label: 'Return',
      value: 'return'
    },
    {
      label: 'Forehand',
      value: 'forehand'
    },
    {
      label: 'Backhand',
      value: 'backhand'
    },
    {
      label: 'Volley',
      value: 'volley'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ];
}

export function getOutcomeButtons() {
  return [
    {
      label: 'Winner',
      value: 'winner'
    },
    {
      label: 'Unforced',
      value: 'unforced'
    },
    {
      label: 'Forced',
      value: 'forced'
    }
  ];
}

export function getOutTypeButtons() {
  return [
    {
      label: 'Net',
      value: 'net'
    },
    {
      label: 'Out',
      value: 'out'
    }
  ];
}

export function getSituationButtons(player1, player2) {
  return [
    {
      label: 'Baseline',
      value: 'baseline'
    },
    {
      label: `${player1} At Net`,
      value: 'player1_at_net'
    },
    {
      label: `${player2} At Net`,
      value: 'player2_at_net'
    },
    {
      label: 'Both At Net',
      value: 'both_at_net'
    },
    {
      label: 'Serving',
      value: 'serving'
    },
    {
      label: 'Returning',
      value: 'returning'
    }
  ];
}

export function getCauseOfErrorInputs() {
  return [
    {
      value: 'cause_of_error_other_input'
    }
  ];
}

export function getCauseOfErrorButtons() {
  return [
    {
      label: 'Human Input',
      value: 'human_input'
    },
    {
      label: 'Ball And Player Tracking',
      value: 'ball_and_player_tracking'
    },
    {
      label: 'Out of Sync',
      value: 'out_of_sync'
    },
    {
      label: 'Stream',
      value: 'stream'
    },
    {
      label: 'Level 1 Feed',
      value: 'level1_feed'
    },
    {
      label: 'Multiple',
      value: 'multiple'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ];
}

export function getIsDisabled(formData, fieldName, key, isReview) {
  return (formData.point_outcome === 'winner' && fieldName === 'out_type') ||
    ((formData.out_type === 'net' || formData.out_type === 'out') &&
      fieldName === 'point_outcome' &&
      key === 'winner') ||
    (!isReview &&
      (formData.last_shot_type === 'serve' || formData.last_shot_type === 'return') &&
      fieldName === 'situation')
    ? true
    : false;
}
