import { atom } from 'recoil';

export const mobileHeaderTextState = atom({
  key: 'mobileHeaderText',
  default: null
});

export const backButtonLinkToState = atom({
  key: 'backButtonLinkTo',
  default: '/matches'
});

export const ablyState = atom({
  key: 'ablyConnected',
  default: false
});

export const userPermissionsState = atom({
  key: 'userPermissions',
  default: {
    roles: [],
    permissions: []
  }
});
