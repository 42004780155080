import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { BAR_HEIGHT } from 'performance/components/constants';

const PerformanceBar = ({ data, dataType }) => {
  const { tournament_name, tier, collector_name, points_collected, accuracy_score } = data;

  let barWidth = `${accuracy_score * 100}%`; // Calculated width of the bar

  const label = dataType === 'collection_live' ? collector_name : `${tournament_name}`;

  return (
    <Flex direction="row" mb={2} alignItems="center" gap={3} minH="45px">
      <Flex direction="column" w="200px">
        <Text fontSize="lg" fontWeight="semibold" color="grey.800" textAlign="right">
          {label}
        </Text>
        {(dataType === 'tournament_weekly' || dataType === 'tournament_daily') && (
          <Text fontSize="xs" fontWeight="semibold" color="grey.600" textAlign="right">
            {tier}
          </Text>
        )}
      </Flex>
      <Box w="100%">
        <Flex
          data-testid="performance-bar"
          h={BAR_HEIGHT}
          w={accuracy_score <= 0.25 ? '33px' : barWidth} // Bar width based on accuracy score but if accuracy score is less than 0.5, set the bar width to 25% to preserve text being displayed in the bar
          backgroundColor={accuracy_score === 0 ? 'none' : 'primary.300'}
          transition="width 0.3s ease-in-out"
          alignItems="center"
          justifyContent="flex-end"
          px={2}
        >
          <Flex direction="row" gap={1} alignItems="center">
            <Text fontSize="sm" fontWeight="bold" color="grey.800">
              {Math.round(accuracy_score * 100)}%
            </Text>
            {dataType === 'collection_live' && (
              <Text fontSize="sm" fontWeight="semibold" color="grey.600">
                ({points_collected})
              </Text>
            )}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

PerformanceBar.propTypes = {
  data: PropTypes.object,
  dataType: PropTypes.oneOf(['collection_live', 'tournament_weekly', 'tournament_daily'])
};

export default PerformanceBar;
