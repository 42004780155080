import { Text, Flex } from '_shared/designSystem/components';
import PropTypes from 'prop-types';
export default function PlayerDetails(props) {
  const { name } = props;
  return (
    <Flex alignItems="center">
      <Flex gap={1}>
        <Text fontSize="sm" color="grey.800">
          {name}
        </Text>
      </Flex>
    </Flex>
  );
}

PlayerDetails.propTypes = {
  name: PropTypes.string
};
