import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { format } from 'date-fns';
import { usePerformanceQuery } from 'performance/hooks/performanceHooks';
import { Box, Flex, Text, ErrorMessage, Spinner, useBreakpointValue } from '_shared/designSystem/components';
import { mobileHeaderTextState } from '_shared/globalState/atoms';
import StickyHeader from '_shared/components/StickyHeader';
import PerformanceBarGroup from './PerformanceBarGroup';
import { DATE_FORMAT } from './constants';

export default function Performance() {
  const { isLoading, error, data } = usePerformanceQuery();

  const setMobileHeaderText = useSetRecoilState(mobileHeaderTextState);

  useEffect(() => {
    setMobileHeaderText('Performance');
  }, [setMobileHeaderText]);

  if (error)
    return (
      <Box>
        <StickyHeader text="Performance" />
        <ErrorMessage message="Unable to retrieve performance data, please try refreshing the page." />
      </Box>
    );

  if (isLoading)
    return (
      <Box>
        <StickyHeader text="Performance" />
        <Flex pt={100} justify="center">
          <Spinner color="primary.500" />
        </Flex>
      </Box>
    );

  const date = new Date();

  return (
    <>
      <StickyHeader text="Performance" />
      <Text fontWeight="semibold" fontSize="lg" px={5} mb={5}>
        {format(date, DATE_FORMAT)}
      </Text>
      <PerformanceContainer data={data} />
    </>
  );
}

export const PerformanceContainer = ({ data }) => {
  const flexDirection = useBreakpointValue({ base: 'column', md: 'row' });
  const boxSize = useBreakpointValue({ base: '100%', md: '50%', sm: '100%' });

  const boxStyles = {
    border: '1px',
    borderColor: 'grey.300',
    p: 5,
    mx: 5,
    mb: 4,
    borderRadius: 'md',
    boxShadow: 'md'
  };

  return (
    <Flex direction={flexDirection} maxW="1600px">
      <Flex direction="column" minWidth={boxSize} gap={6}>
        <Box mb={2} {...boxStyles}>
          <PerformanceBarGroup title="Tournaments Weekly" data={data} dataType="tournament_weekly" />
        </Box>
        <Box {...boxStyles}>
          <PerformanceBarGroup title="Tournaments Daily" data={data} dataType="tournament_daily" />
        </Box>
      </Flex>
      <Flex direction="column" minWidth={boxSize}>
        <Box {...boxStyles}>
          <PerformanceBarGroup title="Collectors Live" data={data} dataType="collection_live" />
        </Box>
      </Flex>
    </Flex>
  );
};
