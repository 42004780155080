import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import theme from '_shared/designSystem/theme';
import { Router, ReactLocation, Outlet, MatchRoute } from '@tanstack/react-location';
import { authenticatedRoutes } from 'routes';
import AppErrorBoundary from '_shared/errors/AppErrorBoundary';
import Authentication from 'authentication/components/Authentication';
import Login from 'authentication/components/Login';
import { RecoilRoot } from 'recoil';
import MainLayout from '_layouts/MainLayout';
import AblyConnection from 'authentication/components/AblyConnection';
import { getCurrentEnvironment } from '_shared/utils/environment/currentEnv';

const queryClient = new QueryClient();

const location = new ReactLocation();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppErrorBoundary>
        <ChakraProvider theme={theme}>
          <RecoilRoot>
            <Router location={location} routes={authenticatedRoutes}>
              <MatchRoute to="login">
                <Login />
              </MatchRoute>
              <Authentication>
                {getCurrentEnvironment() !== 'dev' && <AblyConnection />}
                <MainLayout>
                  <Outlet />
                </MainLayout>
              </Authentication>
            </Router>
          </RecoilRoot>
        </ChakraProvider>
      </AppErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
