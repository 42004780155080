export function setLocalStorageAuthTokens(accessToken, refreshToken, userName) {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  if (userName) localStorage.setItem('userName', userName);
  localStorage.setItem('loggedIn', 'yes');
  localStorage.removeItem('password'); // while transitioning to token auth, clear password that was set from basic auth
}

export function getLocalStorageAuthTokens() {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const userName = localStorage.getItem('userName');
  const loggedIn = localStorage.getItem('loggedIn');
  return {
    accessToken,
    refreshToken,
    userName,
    loggedIn
  };
}

export function removeLocalStorageAuthTokens() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userName');
  localStorage.removeItem('loggedIn');
}
