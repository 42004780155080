import { Box, Flex, Text, Center } from '_shared/designSystem/components';
import Logo from '_shared/components/logo/Logo';
import { Link } from '@tanstack/react-location';
import Logout from 'authentication/components/Logout';
import { userPermissionTypes } from '_shared/constants/user';
import { userPermissionsState } from '_shared/globalState/atoms';
import { checkPermission } from '_shared/utils/permissions';
import { useRecoilValue } from 'recoil';
import { getCurrentEnvironment } from '_shared/utils/environment/currentEnv';
import EnvironmentIndicator from './EnvironmentIndicator';

export default function TopMenu() {
  const { permissions } = useRecoilValue(userPermissionsState);

  return (
    <Box borderBottom="1px" borderBottomColor="grey.200" h="51px" bg="grey.25">
      <Flex gap={10} w="100%">
        <Box w="220px" minW="220px">
          <Link to="/" key="home">
            <Logo />
          </Link>
        </Box>
        {getCurrentEnvironment() !== 'prod' && <EnvironmentIndicator envName={process.env.REACT_APP_ENV} />}
        <Flex justify="space-between" bg="grey.25" w="100%">
          <Flex gap={5}>
            <TopMenuItem label="Matches" link="/matches" />
            {checkPermission(userPermissionTypes.FLAGGED, permissions) && (
              <TopMenuItem label="Flagged" link="/flagged" />
            )}
            {checkPermission(userPermissionTypes.COLLECTION, permissions) && (
              <TopMenuItem label="Performance" link="/performance" />
            )}
          </Flex>
          <Center pr={4}>
            <Logout />
          </Center>
        </Flex>
      </Flex>
    </Box>
  );
}

const TopMenuItem = ({ label, link }) => (
  <Center>
    <Link
      to={link}
      key={link}
      activeOptions={{
        exact: link === '.'
      }}
    >
      {({ isActive }) => {
        return (
          <Text fontSize="xl" color={isActive ? 'primary.800' : 'grey.600'}>
            {label}
          </Text>
        );
      }}
    </Link>
  </Center>
);
