import { apiClient } from '_shared/dataFetching/apiConfig';
import { generateRandomNumber } from '_shared/utils/stringUtil';

export const flaggedService = {
  async getFlagged() {
    const url = `flagged?unique_id=${generateRandomNumber()}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  }
};
