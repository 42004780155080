import Review from 'review/components/Review';

const routes = {
  path: 'review',
  children: [
    {
      path: ':pointJoinKey',
      element: <Review />
    }
  ]
};

export default routes;
