import Collect from 'collect/components/Collect';

const routes = {
  path: 'collect',
  children: [
    {
      path: ':matchId',
      element: <Collect />
    }
  ]
};

export default routes;
