export const collectFieldType = {
  RALLY_LENGTH: 'rally_length',
  LAST_SHOT_TYPE: 'last_shot_type',
  POINT_OUTCOME: 'point_outcome',
  OUT_TYPE: 'out_type',
  SITUATION: 'situation',
  CAUSE_OF_ERROR: 'cause_of_error',
  CAUSE_OF_ERROR_OTHER_INPUT: 'cause_of_error_other_input'
};

export const initialCollectionFormData = {
  rally_length: 0,
  last_shot_type: null,
  point_outcome: null,
  out_type: null,
  situation: null,
  cause_of_error: null
};

export const collectingType = {
  LIVE: 'live',
  LAST_COLLECTED: 'last_collected'
};
