import { apiClient } from '_shared/dataFetching/apiConfig';
import { generateRandomNumber } from '_shared/utils/stringUtil';

let skipStatusEvaluation = false;

export const collectService = {
  async getStart({ matchId, collectFrom }) {
    const url = `collect/${matchId}/start?collect_from=${collectFrom}`;
    const res = await apiClient.get(url);
    return res.data;
  },
  async getPointData({ matchId, set, game, point }) {
    const url = `collect/${matchId}/point_data?set=${set}&game=${game}&point=${point}`;
    const res = await apiClient.get(url);
    return res.data;
  },
  async postCollect({ matchId, pointJoinKey, set, game, point, fieldName, value }) {
    const formData = new FormData();
    formData.append('set', set);
    formData.append('game', game);
    formData.append('point', point);
    formData.append(fieldName, value);
    const headers = {
      'Content-Type': 'application/form-data',
      Username: localStorage?.userName
    };
    const url = `collect/${matchId}/point/${pointJoinKey}`;
    const res = await apiClient.post(url, formData, { headers: headers });
    return res.data;
  },
  async getNextPoint({matchId, pointJoinKey, set, game, point}) {
    const url = `collect/${matchId}/next_point/${pointJoinKey}?set=${set}&game=${game}&point=${point}&unique_id=${generateRandomNumber()}`;
    const res = await apiClient.get(url);
    return res.data;
  },
  async getNextPointNoCheck({matchId, pointJoinKey, set, game, point}) {
    const url = `collect/${matchId}/next_point/${pointJoinKey}?unique_id=${generateRandomNumber()}&set=${set}&game=${game}&point=${point}&skip_checks=yes&skip_status_eval=${
        skipStatusEvaluation ? 'yes' : 'no'
    }`;
    const res = await apiClient.get(url);
    skipStatusEvaluation = false;
    return res.data;
  },

  async postCannotCollect({ matchId, pointJoinKey, set, game, point, fieldName, value }) {
    skipStatusEvaluation = true;
    const formData = new FormData();
    formData.append('set', set);
    formData.append('game', game);
    formData.append('point', point);
    formData.append(fieldName, value);
    const headers = {
      'Content-Type': 'application/form-data',
      Username: localStorage?.userName
    };
    const url = `collect/${matchId}/point/${pointJoinKey}/cannot_collect`;
    const res = await apiClient.post(url, formData, { headers: headers });
    return res.data;
  }
};
