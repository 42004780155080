export function checkPermission(permissionName, permissionList) {
  const performCheck = true;

  if (performCheck) {
    if (process.env.NODE_ENV === 'test' || process.env.REACT_APP_ENV === 'dev' || process.env.STORYBOOK === 'on')
      return true;
    if (!permissionName || !permissionList) return false;
    if (permissionList.includes('*')) return true;
    if (permissionList.includes(permissionName)) return true;
    return false;
  } else return true;
}
