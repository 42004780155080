import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from '@chakra-ui/react';
import PerformanceBar from './PerformanceBar';
import { TARGET_PERCENTAGE } from 'performance/components/constants';

const PerformanceBarGroup = ({ title, data, dataType }) => {
  const dataForType = data?.[dataType];

  return (
    <Box position="relative">
      <Text fontWeight="semibold" fontSize="2xl" color="grey.800" mb={5}>
        {title}
      </Text>
      {dataForType && dataForType.length > 0 ? (
        <>
          {dataForType.map((item) => (
            <PerformanceBar
              key={dataType === 'collection_live' ? item.collector_name : item.tournament_name}
              data={item}
              dataType={dataType}
            />
          ))}
          <TargetPercentageLine />
        </>
      ) : (
        <Text color="grey.600">Currently no data to display</Text>
      )}
    </Box>
  );
};

const TargetPercentageLine = () => {
  const rightPosition = 'calc(5% - 27px)';
  return (
    <Flex
      direction="column"
      position="absolute"
      w="40px"
      h="100%"
      top={0}
      right={rightPosition}
      alignItems="center"
      pb={1}
    >
      <Text fontWeight="medium" color="grey.800" mb={2}>
        {TARGET_PERCENTAGE * 100}%
      </Text>
      <Box w="1px" border="1px" borderColor="error.400" borderStyle="dashed" h="100%" />
    </Flex>
  );
};

PerformanceBarGroup.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  dataType: PropTypes.oneOf(['collection_live', 'tournament_weekly', 'tournament_daily']).isRequired
};

PerformanceBarGroup.defaultProps = {
  title: ''
};

export default PerformanceBarGroup;
