import { Box, Flex, Text } from '_shared/designSystem/components';

const ServingSideGraphic = ({ point }) => {
  const isOddPoint = point % 2 === 1;

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column" gap={2} m="0 25px">
      <Text fontWeight="semibold" fontSize="sm">
        Serving Side
      </Text>
      <Box width="100px" height="150px" bg="white" border="2px solid" borderColor="primary.500" position="relative">
        <Box
          width="50%"
          height="50%"
          bg="primary.500"
          position="absolute"
          top={0}
          left={isOddPoint ? 0 : '50%'}
          display="flex"
          justifyContent="center"
          alignItems="center"
          data-testid="top-serving-side"
        />
        <Box
          width="50%"
          height="50%"
          bg="primary.500"
          position="absolute"
          bottom={0}
          right={isOddPoint ? 0 : '50%'}
          display="flex"
          justifyContent="center"
          alignItems="center"
          data-testid="bottom-serving-side"
        />
      </Box>
    </Flex>
  );
};

export default ServingSideGraphic;
