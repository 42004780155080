import { useState, useEffect } from 'react';
import { Box, Flex, ErrorMessage, Spinner } from '_shared/designSystem/components';
import { stickyHeaderHeight, topMenuHeight } from '_layouts/margins';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { backButtonLinkToState, mobileHeaderTextState } from '_shared/globalState/atoms';
import FlaggedTable from './FlaggedTable';
import { useNavigate } from '@tanstack/react-location';
import StickyHeader from '_shared/components/StickyHeader';
import { useFlaggedQuery } from 'flagged/hooks/flaggedHooks';
import FlaggedSearchForm from './FlaggedSearchForm';
import { SearchWrapper } from '_shared/components/Headers';
import { performAllFlaggedSearch } from 'flagged/util/flaggedUtil';
import { userPermissionTypes } from '_shared/constants/user';
import { userPermissionsState } from '_shared/globalState/atoms';
import { checkPermission } from '_shared/utils/permissions';
import NoPermissionAlert from '_shared/components/NoPermissionAlert';

export default function Flagged() {
  const setMobileHeaderText = useSetRecoilState(mobileHeaderTextState);

  const { permissions } = useRecoilValue(userPermissionsState);

  const { isLoading, error, data, isSuccess } = useFlaggedQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      handleScroll();
    }
  }, [isSuccess]);

  // Initial states are set based on sessionStorage values, or default to initial values
  const [searchMatchId, setSearchMatchId] = useState(sessionStorage.getItem('searchMatchId') || '');
  const [searchTournamentId, setSearchTournamentId] = useState(sessionStorage.getItem('searchTournamentId') || '');
  const [searchDataStatus, setSearchDataStatus] = useState(sessionStorage.getItem('searchDataStatus') || 'all');

  useEffect(() => {
    setMobileHeaderText('Flagged');
  }, [setMobileHeaderText]);

  const setbackButton = useSetRecoilState(backButtonLinkToState);

  const openReviewPage = (matchId, pointJoinKey, navigate) => {
    setbackButton('/flagged');
    sessionStorage.setItem('FlaggedScrollPosition', window.pageYOffset);
    navigate({ to: `/review/${pointJoinKey}?matchId=${matchId}` });
  };

  const handleSubmitForm = (matchId, tournamentId, dataStatus) => {
    setSearchMatchId(matchId);
    setSearchTournamentId(tournamentId);
    setSearchDataStatus(dataStatus);

    sessionStorage.setItem('searchMatchId', matchId);
    sessionStorage.setItem('searchTournamentId', tournamentId);
    sessionStorage.setItem('searchDataStatus', dataStatus);
  };

  if (error)
    return (
      <Box px={3}>
        <StickyHeader text="Flagged" />
        <ErrorMessage message="Unable to retrieve flagged points, please try refreshing the page." />
      </Box>
    );

  if (isLoading)
    return (
      <Box>
        <StickyHeader text="Flagged" />
        <Flex pt={100} justify="center">
          <Spinner color="primary.500" />
        </Flex>
      </Box>
    );

  const handleScroll = () => {
    const FlaggedScrollPosition = sessionStorage.getItem('FlaggedScrollPosition');
    if (FlaggedScrollPosition) {
      window.scrollTo(0, parseInt(FlaggedScrollPosition));
      sessionStorage.removeItem('FlaggedScrollPosition');
    }
  };

  if (!checkPermission(userPermissionTypes.FLAGGED, permissions)) {
    return <NoPermissionAlert />;
  }

  const topHeaderHeight = '50px';

  return (
    <Box>
      <StickyHeader text="Flagged" />
      <SearchWrapper topHeaderHeight={topHeaderHeight}>
        <FlaggedSearchForm handleSubmitForm={handleSubmitForm} />
      </SearchWrapper>
      <FlaggedTable
        data={performAllFlaggedSearch(data, searchMatchId, searchTournamentId, searchDataStatus)}
        headerTop={{ base: 0, md: `${topMenuHeight + stickyHeaderHeight}px` }}
        openReviewPage={openReviewPage}
        navigate={navigate}
      />
    </Box>
  );
}
