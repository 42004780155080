import { useRef } from 'react';
import { mobileHeaderHeight, sideMenuWidth } from '_layouts/margins';
import {
  Box,
  Text,
  Flex,
  Icon,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '_shared/designSystem/components';
import SideMenu from './SideMenu';
import { mobileHeaderTextState } from '_shared/globalState/atoms';
import { useRecoilValue } from 'recoil';
import { Link } from '@tanstack/react-location';
import { getCurrentEnvironment } from '_shared/utils/environment/currentEnv';

export default function MobileHeader() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuIconRef = useRef();
  const mobileHeaderText = useRecoilValue(mobileHeaderTextState);
  return (
    <Box>
      <Flex
        bg={getCurrentEnvironment() === 'prod' ? 'grey.800' : 'blue.800'}
        h={`${mobileHeaderHeight}px`}
        align="center"
        px={2}
        justify="space-between"
      >
        <Box onClick={onOpen}>
          <Icon name="menu" color="white" width={5} height={5} _hover={{ cursor: 'pointer' }} />
        </Box>
        <Text color="white" fontSize={{ base: 'lg', sm: '2xl' }} fontWeight="semi-bold">
          {mobileHeaderText}
        </Text>
        <Link to="/" key="home">
          <Box>
            <Icon name="logoWhite" width="22px" height="22px" />
          </Box>
        </Link>
        <SideMenuDrawer isOpen={isOpen} onClose={onClose} menuIconRef={menuIconRef} />
      </Flex>
    </Box>
  );
}

export const SideMenuDrawer = ({ isOpen, onClose, menuIconRef }) => (
  <Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={menuIconRef}>
    <DrawerOverlay />
    <DrawerContent maxWidth={`${sideMenuWidth}px`} bg="grey.800">
      <Box>
        <DrawerCloseButton color="white" />
      </Box>
      <Box>
        <SideMenu isMobile closeMenu={onClose} />
      </Box>
    </DrawerContent>
  </Drawer>
);
