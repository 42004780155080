import { Button as ChakraButton, useStyleConfig } from '@chakra-ui/react';

export const ButtonStyles = {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'base',
    outline: 'none'
  },
  sizes: {
    sm: {
      fontSize: 'sm'
    },
    md: {
      fontSize: 'md'
    }
  },
  variants: {
    outline: {
      border: '2px solid',
      borderColor: 'primary.500',
      color: 'primary.500',
      _hover: { bg: 'primary.50' }
    },
    solid: {
      bg: 'primary.500',
      color: 'white',
      _hover: { bg: 'primary.700' }
    }
  },
  defaultProps: {
    size: 'md',
    variant: 'solid'
  }
};

function Button(props) {
  const { variant, size, ...rest } = props;

  const styles = useStyleConfig('Button', { variant, size });

  return <ChakraButton __css={styles} {...rest} />;
}

export default Button;
