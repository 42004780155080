import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  ErrorMessage,
  Button,
  useDisclosure,
  Center,
  Text,
  LoadingSpinner
} from '_shared/designSystem/components';
import { useSetRecoilState } from 'recoil';
import { backButtonLinkToState, mobileHeaderTextState } from '_shared/globalState/atoms';
import PointHistoryTable from './PointHistoryTable';
import { useMatch, useNavigate } from '@tanstack/react-location';
import StickyHeader from '_shared/components/StickyHeader';
import { useMatchQuery } from 'match/hooks/matchHooks';
import isEmpty from 'lodash/isEmpty';
import { MatchInfoHeader } from './MatchInfoHeader';
import { collectingType } from '_shared/constants/collectionTypes';
import { PointModal } from './PointModal';
import { collectService } from 'collect/services/collectService';
import { useMutation } from 'react-query';

export default function Match() {
  const {
    params: { matchId }
  } = useMatch();

  const [pointModalData, setPointModalData] = useState(null);
  const setMobileHeaderText = useSetRecoilState(mobileHeaderTextState);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isLoading, error, data } = useMatchQuery(matchId);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  const players = `${data?.match_info?.player1Name} v ${data?.match_info?.player2Name}`;

  const { mutate: getStartMutate } = useMutation(collectService.getStart, {
    onSuccess: (data, variables) => {
      if (data?.set && data?.game && data?.point) {
        navigate({ to: `/collect/${matchId}?set=${data?.set}&game=${data?.game}&point=${data?.point}` });
      } else {
        setErrorMessage('An error has occured retrieving the point data. Please try again');
      }
    },
    onError: () => setErrorMessage('An error has occured retrieving the point data. Please try again')
  });

  useEffect(() => {
    if (!isEmpty(players)) {
      setMobileHeaderText(players);
    }
  }, [setMobileHeaderText, players]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const openPointData = (data) => {
    setPointModalData(data);
    onOpen();
  };

  const setbackButton = useSetRecoilState(backButtonLinkToState);

  const handleButtonClick = (collectFrom) => {
    setbackButton(`/match/${matchId}`);
    getStartMutate({ matchId, collectFrom });
  };

  if (error)
    return (
      <Box>
        <StickyHeader text="Match Info" />
        <ErrorMessage message="Unable to retrieve match info, please try refreshing the page." />
      </Box>
    );

  if (isLoading) return <LoadingSpinner />;

  return (
    <Box>
      <Box px={5} mt={2}>
        <MatchInfoHeader matchInfo={data?.match_info} />
      </Box>
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <MatchForm handleButtonClick={handleButtonClick} />
      <Box overflowX={{ base: 'auto', md: 'initial' }}>
        <PointHistoryTable data={data?.point_info} openPointData={openPointData} />
      </Box>
      <PointModal data={pointModalData} isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

export const MatchForm = ({ handleButtonClick }) => (
  <Box p={5}>
    <Flex gap={3}>
      <Center>
        <Text fontSize="lg" fontWeight="semibold">
          Collect From
        </Text>
      </Center>
      <Flex gap={3}>
        <Button size="sm" onClick={() => handleButtonClick(collectingType.LIVE)}>
          Live
        </Button>
        <Button size="sm" onClick={() => handleButtonClick(collectingType.LAST_COLLECTED)}>
          Last Collected Point
        </Button>
      </Flex>
    </Flex>
  </Box>
);
