import { Alert, AlertIcon, AlertDescription, Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { bannerAlertType } from '_shared/constants/alertTypes';

export default function ErrorMessage(props) {
  const { message } = props;
  return (
    <Box px={3}>
      <Alert status={bannerAlertType.ERROR} variant="left-accent">
        <AlertIcon />
        <AlertDescription>{message}</AlertDescription>
      </Alert>
    </Box>
  );
}

ErrorMessage.propTypes = {
  message: PropTypes.string
};
