import { Tooltip as ChakraTooltip } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Tooltip = (props) => {
  const { tooltipText, elementToHover, bg, textColour } = props;
  return (
    <ChakraTooltip
      hasArrow
      label={tooltipText}
      bg={bg ? bg : 'grey.900'}
      color={textColour ? textColour : 'white'}
      borderRadius={4}
      placement="auto"
      fontSize="xs"
      p={3}
    >
      {/* If using an icon as the element, wrap the icon in a span tag, else it won't work correctly. See 
      https://chakra-ui.com/docs/overlay/tooltip#with-an-icon for more info */}
      {elementToHover}
    </ChakraTooltip>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  tooltipText: PropTypes.string.isRequired,
  elementToHover: PropTypes.node.isRequired,
  bg: PropTypes.string,
  textColour: PropTypes.string
};
