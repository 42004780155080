import { useState } from 'react';
import { Box, Input, Flex, Button, ErrorMessage } from '_shared/designSystem/components';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/form-control';
import { Formik, Form } from 'formik';
import { useMutation } from 'react-query';
import { authenticationService } from 'authentication/services/authenticationService';
import { setLocalStorageAuthTokens } from '_shared/utils/localStorage';
import Logo from '_shared/components/logo/Logo';

export default function Login() {
  const [formSubmitError, setFormSubmitError] = useState(null);
  const [formUserName, setFormUserName] = useState(null);

  const formSubmitErrorMessage = 'Your user name and password were not recognised, please try again';

  const mutation = useMutation(
    (credentials) =>
      authenticationService.validateCredentialsToken({
        credentials
      }),
    {
      onSuccess: (data) => {
        if (data?.access_token) {
          setLocalStorageAuthTokens(data?.access_token, data?.refresh_token, formUserName);
          window.location.href = '/';
        } else {
          setFormSubmitError(formSubmitErrorMessage);
        }
      },
      onError: (error) => {
        setFormSubmitError(formSubmitErrorMessage);
      }
    }
  );

  return (
    <Formik
      initialValues={{ userName: '', password: '' }}
      validate={(values) => {
        setFormSubmitError(null);
        setFormUserName(values.userName);
        const errors = {};
        if (!values.userName) {
          errors.userName = 'Please enter your user name';
        }
        if (!values.password) {
          errors.password = 'Please enter your password';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        mutation.mutate(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values, errors, touched, handleChange, handleBlur }) => (
        <Form>
          <Box ml="auto" mr="auto" w="360px" mt={200}>
            <Flex direction="column" gap="20px">
              <Logo />
              <FormControl isInvalid={errors.userName && touched.userName}>
                <FormLabel htmlFor="userName" fontSize="sm" fontWeight="medium" color="grey.700">
                  User Name
                </FormLabel>
                <Input
                  id="userName"
                  name="userName"
                  h="44px"
                  borderRadius={'5px'}
                  placeholder="Enter your user name"
                  size="sm"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.userName}
                />
                <FormErrorMessage>{errors.userName}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.password && touched.password}>
                <FormLabel htmlFor="password" fontSize="sm" fontWeight="medium" color="grey.700">
                  Password
                </FormLabel>
                <Input
                  id="password"
                  name="password"
                  h="44px"
                  borderRadius={'5px'}
                  placeholder="Enter your password"
                  size="sm"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>
              {formSubmitError && <ErrorMessage message={formSubmitError} />}
              <Button spinnerPlacement="start" type="submit" disabled={isSubmitting}>
                Sign In
              </Button>
            </Flex>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
