import { useEffect, useState } from 'react';
import { Box, Flex, ErrorMessage, Spinner, Text } from '_shared/designSystem/components';
import { useSetRecoilState } from 'recoil';
import { mobileHeaderTextState } from '_shared/globalState/atoms';
import { useSearch, useMatch, useNavigate } from '@tanstack/react-location';
import StickyHeader from '_shared/components/StickyHeader';
import isEmpty from 'lodash/isEmpty';
import { MatchInfoHeader } from 'match/components/MatchInfoHeader';
import { useGetReviewQuery } from 'review/hooks/reviewHooks';
import ComparisonScores from './ComparisonScores';
import CollectionForm from 'collect/components/CollectionForm';
import { collectFieldType, initialCollectionFormData } from '_shared/constants/collectionTypes';
import { reviewService } from 'review/services/reviewService';
import { useMutation } from 'react-query';
import { userPermissionTypes } from '_shared/constants/user';
import { userPermissionsState } from '_shared/globalState/atoms';
import { checkPermission } from '_shared/utils/permissions';
import { useRecoilValue } from 'recoil';
import NoPermissionAlert from '_shared/components/NoPermissionAlert';

export default function Review() {
  const {
    params: { pointJoinKey }
  } = useMatch();

  const [errorMessage, setErrorMessage] = useState(null);

  const { matchId } = useSearch();
  const navigate = useNavigate();

  const setMobileHeaderText = useSetRecoilState(mobileHeaderTextState);
  const [formData, setFormData] = useState(initialCollectionFormData);

  const { isLoading, error, data } = useGetReviewQuery(matchId, pointJoinKey);

  const players = `${data?.match_info?.player1Name} v ${data?.match_info?.player2Name}`;

  const { mutate: postReviewMutate } = useMutation(reviewService.postReview, {
    onSuccess: (data) => {
      navigate({ to: `/flagged` });
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        setErrorMessage('You do not have permission to perform this action');
      } else {
        setErrorMessage('An error occured when submitting the review. Please try again');
      }
    }
  });

  const { mutate: postCannotReviewMutate } = useMutation(reviewService.postCannotReview, {
    onSuccess: (data) => {
      navigate({ to: `/flagged` });
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        setErrorMessage('You do not have permission to perform this action');
      } else {
        setErrorMessage('An error occured when submitting the review. Please try again');
      }
    }
  });

  useEffect(() => {
    if (!isEmpty(players)) {
      setMobileHeaderText(`Review - ${players}`);
    }
  }, [setMobileHeaderText, players]);

  useEffect(() => {
    if (data?.point_data?.manual) {
      setFormData(data?.point_data?.manual);
    }
  }, [setFormData, data?.point_data?.manual]);

  const { permissions } = useRecoilValue(userPermissionsState);

  if (!checkPermission(userPermissionTypes.REVIEW, permissions)) {
    return <NoPermissionAlert />;
  }

  const updateFormData = (field, value) => {
    const newFormData = { ...formData };
    if (field !== collectFieldType.RALLY_LENGTH && formData[`${field}`] === value) {
      newFormData[`${field}`] = null;
    } else {
      newFormData[`${field}`] = value;
    }
    setFormData(newFormData);
  };

  const handleNextOrSubmit = async () => {
    const dataToSubmit = {
      matchId,
      pointJoinKey,
      set: data?.set,
      game: data?.game,
      point: data?.point,
      formData
    }
    postReviewMutate(dataToSubmit);
  };

  const handleCannotReviewItem = async () => {
    const dataToSubmit = {
      matchId,
      pointJoinKey,
      set: data?.set,
      game: data?.game,
      point: data?.point
    };
    postCannotReviewMutate(dataToSubmit);
  };

  if (error)
    return (
      <Box>
        <StickyHeader text="Review" />
        <ErrorMessage message="Unable to retrieve review info, please try refreshing the page." />
      </Box>
    );

  if (isLoading)
    return (
      <Box>
        <Flex pt={100} justify="center">
          <Spinner color="primary.500" />
        </Flex>
      </Box>
    );

  return (
    <Flex justify="center">
      <Flex px={5} mt={2} gap={8} direction="column" maxW="2200px" pb={10} align="center">
        <MatchInfoHeader
          subheading="Review"
          matchInfo={data?.match_info}
          matchId={matchId}
          pointJoinKey={pointJoinKey}
        />
        {errorMessage && <ErrorMessage message={errorMessage} />}
        <Flex justify={{ base: 'center', md: 'initial' }}>
          <ScoreInfo
            score={data?.flagged_point_score}
            pointJoinKey={pointJoinKey}
            set={data?.set}
            game={data?.game}
            point={data?.point}
          />
        </Flex>
        <Flex flexWrap="wrap" gap={5} mb={{ base: 5, md: 0 }}>
          {/* <Box w={{ base: '100%', sm: '100%', md: '100%', lg: '600px', xl: '750px', '2xl': '750px' }}>
            <VideoPlayer url={data?.match_info?.video_url} />
          </Box> */}
          <Flex justify="center" align="center">
            <ComparisonScores
              data={data?.point_data}
              player1Name={data?.match_info?.player1Name}
              player2Name={data?.match_info?.player2Name}
            />
          </Flex>
        </Flex>
        <CollectionForm
          formData={formData}
          updateFormData={updateFormData}
          player1Name={data?.match_info?.player1Name}
          player2Name={data?.match_info?.player2Name}
          submitCollectionItem={null}
          handleNextOrSubmit={handleNextOrSubmit}
          handleCannotReviewItem={handleCannotReviewItem}
          reviewing={true}
        />
      </Flex>
    </Flex>
  );
}

const ScoreInfo = ({ score, pointJoinKey, set, game, point }) => (
  <Flex direction="column" gap={3}>
    <Flex gap={4} justify="center">
      <Text>Score at point:</Text>
      <Flex gap={3}>
        <Text>{`${nth(score?.set_number)} Set`}</Text>
        <Text>{score?.game_score}</Text>
        <Text>{score?.point_score}</Text>
      </Flex>
    </Flex>
    <Text>{`Set ${set} - Game ${game} - Point ${point} - PJK ${pointJoinKey}`}</Text>
  </Flex>
);

export function nth(n) {
  return `${n}${['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th'}`;
}
