import { useToast } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function Toast() {
  const toast = useToast();

  const addToast = (props) => {
    toast({
      description: props.message,
      status: props.status,
      position: 'top-right',
      duration: 3000,
      variant: 'left-accent'
    });
  };

  return { addToast };
}

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['success', 'error', 'warning', 'info']).isRequired
};

Toast.defaultProps = {
  message: 'You do not have permission to view this content.',
  status: 'warning'
};
